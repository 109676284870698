import React, { useState } from "react";
import "./contactButton.scss";
import Chat from "../../assets/images/chat.png";
import ChatVariant from "../../assets/images/chat-variant.png";
import ContactPopup from "./contactPopup";

const ContactButton = () => {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  return (
    <div className="contact-container">
      <div
        className="contact-button"
        onClick={() => setIsPopupOpen(!isPopupOpen)}
      >
        <img src={Chat} alt="Chat" className="chat-icon" />
        <img
          src={ChatVariant}
          alt="Chat Variant"
          className="chat-variant-icon"
        />
      </div>
      <div className={`popup${isPopupOpen ? " open" : ""}`}>
        {isPopupOpen && <ContactPopup />}
      </div>
    </div>
  );
};

export default ContactButton;
