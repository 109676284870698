import React from "react";
import "./contactPopup.scss";

const ContactPopup = () => {
  const email = "founders@goguava.app";

  return (
    <div className="contact-popup">
      <div className="title">
        <p>Reach out directly to the founders</p>
      </div>
      <div className="email-container">
        <a className="email" href={`mailto:${email}`}>
          {email}
        </a>
      </div>
    </div>
  );
};

export default ContactPopup;
