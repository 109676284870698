import React from "react";
import "./founderInfo.scss";
import { link } from "fs";

interface Props {
  name: string;
  img: string;
  linkedinLogo?: string;
  linkedinLink?: string;
  xLogo?: string;
  xLink?: string;
}

const FounderInfo: React.FC<Props> = ({
  name,
  img,
  linkedinLogo,
  linkedinLink,
  xLogo,
  xLink,
}) => {
  return (
    <div className="founder-info">
      <div className="founder-image">
        <img src={img} alt={name} />
      </div>
      <div className="founder-text">
        <h2>{name}</h2>
        <div className="link_image_container">
          {linkedinLink && (
            <a href={linkedinLink}>
              <img src={linkedinLogo} alt="linkedin" />
            </a>
          )}
          {xLink && (
            <a href={xLink}>
              <img src={xLogo} alt="x" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FounderInfo;
