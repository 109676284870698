import React, { useState } from "react";
import "./verification.scss";
import VerificationInput from "react-verification-input";
import {
  CognitoIdentityProviderClient,
  SignUpCommand,
  ResendConfirmationCodeCommand,
  ConfirmSignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
const REGION = process.env.REACT_APP_REGION;
const APP_CLIENT_ID = process.env.REACT_APP_APP_CLIENT_ID;

interface VerificationProps {
  setEmailVerified: (emailVerified: boolean) => void;
  email: string;
  username: string;
}

const Verification = ({
  setEmailVerified,
  email,
  username,
}: VerificationProps) => {
  const client = new CognitoIdentityProviderClient({ region: REGION?.trim() });

  const [code, setCode] = useState("");

  const emailVerification = (code: string) => {
    const params = {
      ClientId: APP_CLIENT_ID,
      ConfirmationCode: code,
      Username: username,
    };
    const command = new ConfirmSignUpCommand(params);
    client.send(command).then(
      (data) => {
        setEmailVerified(true);
      },
      (error) => {
        console.log(error);
        console.log(code);
        alert("Form invalid!");
      }
    );
  };

  return (
    <div className="verification-container">
      <div className="header-text">
        <p>We've sent a verification code to {email}</p>
      </div>
      <VerificationInput
        value={code}
        length={6}
        validChars="0-9"
        placeholder=""
        autoFocus={true}
        onChange={(value) => {
          setCode(value);
        }}
        onComplete={(value) => {
          setCode(value);
          emailVerification(value);
        }}
        classNames={{
          container: "verification-input-container",
          character: "verification-input-character",
          characterInactive: "verification-input-character-inactive",
          characterSelected: "verification-input-character-selected",
        }}
      />
      <div className="resend-container">
        <p>Didn't receive a code?</p>
        <button
          onClick={() => {
            const params = {
              ClientId: APP_CLIENT_ID,
              Username: username,
            };
            const command = new ResendConfirmationCodeCommand(params);
            client.send(command).then(
              (data) => {},
              (error) => {
                console.log(error);
                alert("Could not send email!");
              }
            );
          }}
        >
          Resend
        </button>
      </div>
    </div>
  );
};

export default Verification;
