import {
  CognitoIdentityProviderClient,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import React, { useEffect, useState } from "react";
import Check from "../vectors/check";
import "./form.scss";
const REGION = process.env.REACT_APP_REGION;
const APP_CLIENT_ID = process.env.REACT_APP_APP_CLIENT_ID;
const PUBLIC_API_ID = process.env.REACT_APP_PUBLIC_API_ID;
const STAGE = process.env.REACT_APP_STAGE;

interface FormProps {
  setIsSubmitted: (isSubmitted: boolean) => void;
  setEmail: (email: string) => void;
  setUsername: (username: string) => void;
  setName: (name: string) => void;
}

const Form: React.FC<FormProps> = ({
  setIsSubmitted,
  setEmail,
  setUsername,
  setName,
}) => {
  const client = new CognitoIdentityProviderClient({ region: REGION?.trim() });

  const CHECKUSERNAME_API_PATH = `https://${PUBLIC_API_ID}.execute-api.${REGION}.amazonaws.com/${STAGE}/check-username`;

  const [password, setPassword] = useState("");
  const [localName, setLocalName] = useState("");
  const [localUsername, setLocalUsername] = useState("");
  const [nameValid, setNameValid] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [localEmail, setLocalEmail] = useState("");

  const [signUp, setSignUp] = useState(false);

  const [usernameInput, setUsernameInput] = useState("");

  const namePattern = /^[A-Za-z\s]{6,32}$/;
  const usernamePattern = /^[a-z0-9](?!.*?_{2})[a-z0-9_]{2,14}[a-z0-9]$/;
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passwordPattern =
    /^(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}\[\]:;<>,.?~\\-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  const QUERY_DELAY = 1000;

  const checkValidity = (value: string, pattern: RegExp): boolean => {
    return pattern.test(value);
  };

  const checkUsername = async (
    value: string,
    pattern: RegExp
  ): Promise<boolean> => {
    if (pattern.test(value)) {
      const response = await fetch(
        `${CHECKUSERNAME_API_PATH}?username=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.message === "username available") {
          return true;
        } else {
          return false;
        }
      } else {
        console.error(`Error: ${response.status}`);
        return false;
      }
    } else {
      return false;
    }
  };

  const handleNameChange = (value: string) => {
    setName(value);
    setLocalName(value);
    setNameValid(checkValidity(value, namePattern));
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      if (
        usernameInput !== undefined &&
        usernameInput !== null &&
        usernameInput.trim() !== ""
      ) {
        checkUsername(usernameInput, usernamePattern).then((result) => {
          setUsernameValid(result);
        });
      }
    }, QUERY_DELAY);

    return () => clearTimeout(debounceTimeout);
  }, [usernameInput]);

  const handleUsernameChange = (value: string) => {
    setUsername(value);
    setLocalUsername(value);
    setUsernameInput(value);
    // clearTimeout(debounceTimeout);
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setLocalEmail(value);
    setEmailValid(checkValidity(value, emailPattern));
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordValid(checkValidity(value, passwordPattern));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (usernameValid && emailValid && passwordValid) {
      const params = {
        ClientId: APP_CLIENT_ID?.trim(),
        Username: localUsername,
        Password: password,
        UserAttributes: [
          {
            Name: "name",
            Value: localName,
          },
          {
            Name: "email",
            Value: localEmail,
          },
        ],
      };
      const command = new SignUpCommand(params);
      client.send(command).then(
        (data) => {
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error);
          alert("Form invalid!");
        }
      );
    } else {
      alert("Form invalid!");
    }

    const button = document.getElementById("button");

    if (button && usernameValid && emailValid && passwordValid) {
      button.classList.add("onclick");
      setTimeout(() => {
        button.classList.remove("onclick");
        button.classList.add("validate");
        setTimeout(() => {
          button.classList.remove("validate");
          setIsSubmitted(true);
        }, 400);
      }, 2000);
    }
  };

  const handleSignUp = () => {
    setSignUp(true);
  };

  return (
    <div className="form-container-class">
      <h1>Coming soon</h1>
      {!signUp ? (
        <button id="button" className="secure-button" onClick={handleSignUp}>
          Secure your username
        </button>
      ) : (
        <form className="form" onSubmit={handleSubmit}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Name"
              value={localName}
              onChange={(e) => handleNameChange(e.target.value)}
              required
              maxLength={16}
            />
            {nameValid && (
              <div className="check">
                <Check />
              </div>
            )}
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Username"
              value={localUsername}
              onChange={(e) => handleUsernameChange(e.target.value)}
              required
              maxLength={16}
            />
            {usernameValid && (
              <div className="check">
                <Check />
              </div>
            )}
          </div>
          <div className="input-container">
            <input
              type="text"
              placeholder="Email"
              onChange={(e) => handleEmailChange(e.target.value)}
              required
              maxLength={32}
            />
            {emailValid && (
              <div className="check">
                <Check />
              </div>
            )}
          </div>
          <div className="input-container">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => handlePasswordChange(e.target.value)}
              required
              maxLength={32}
            />
            {passwordValid && (
              <div className="check">
                <Check />
              </div>
            )}{" "}
          </div>
          <button id="button" type="submit">
            Submit
          </button>
        </form>
      )}
    </div>
  );
};

export default Form;
