import "./App.scss";
import React from "react";

import Logo from "./components/headerComponents/logo";

import ParallaxComponent from "./components/parallax/parallax";
import MobileComponent from "./components/mobile/mobile";
import { ParallaxProvider } from "react-scroll-parallax";

const App: React.FC = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  console.log(height);
  return (
    <ParallaxProvider>
      <div className="App">
        {width < 450 || height < 450 ? (
          <MobileComponent />
        ) : (
          <ParallaxComponent />
        )}
      </div>
    </ParallaxProvider>
  );
};

export default App;
