import React, { useState } from "react";

import MockupStraight from "../../assets/images/mockup-straight.png";
import RewardsMockup from "../../assets/images/rewards-mockup.png";
import ListMockup from "../../assets/images/list-mockup.png";
import ContributionMockup from "../../assets/videos/contribution-mockup.mp4";

import Aaron from "../../assets/images/aaron.png";
import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import Joel from "../../assets/images/joel.png";
import Linkedin from "../../assets/images/linkedin.png";
import Tiktok from "../../assets/images/tiktok.png";
import X from "../../assets/images/x.png";

import LinkedInLogo from "../../assets/images/linkedin-logo.png";
import XLogo from "../../assets/images/x-logo.png";

import ContactButton from "../contactComponents/contactButton";
import Form from "../formComponents/form";
import FormSubmitted from "../formComponents/formSubmitted";
import Verification from "../formComponents/verification";
import FounderInfo from "../founderComponents/founderInfo";
import Logo from "../headerComponents/logo";
import InfoCard from "../howToComponents/infoCard";

import ReplayBlue from "../../assets/images/replay-blue.png";
import ReplayGreen from "../../assets/images/replay-green.png";
import ReplayYellow from "../../assets/images/replay-yellow.png";

import Fade from "react-reveal/Fade";
import Tada from "react-reveal/Tada";

import "../../_variables.scss";
import "./mobile.scss";

const MobileComponent: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [name, setName] = useState<string>("");
  return (
    <div className="parallax-container">
      <div className="contact-button-container">
        <ContactButton />
      </div>
      <Fade top>
        <div className="header">
          <Logo />
        </div>
      </Fade>
      <Fade bottom>
        <div className="text-container">
          <h1>
            Live Grocery Insights. <br /> Smart Savings Start Here.
          </h1>
        </div>
      </Fade>
      <Fade top>
        <div className="form-container">
          {isSubmitted ? (
            emailVerified ? (
              <p className="welcome-text">
                Welcome aboard, <span>{name}</span>!
              </p>
            ) : (
              <Verification
                setEmailVerified={setEmailVerified}
                email={email}
                username={username}
              />
            )
          ) : (
            <Form
              setIsSubmitted={setIsSubmitted}
              setEmail={setEmail}
              setUsername={setUsername}
              setName={setName}
            />
          )}
        </div>
      </Fade>
      <div>
        <Fade bottom>
          <div className="save-card-container">
            <InfoCard
              title="Save"
              text="Our real-time price comparison enables you to make informed choices and maximize your savings on groceries"
              color="#154F51"
              backgroundColor="#7BDADD"
              image={ListMockup}
              replay={ReplayBlue}
            />
          </div>
        </Fade>
        <Fade bottom>
          <div className="contribute-card-container">
            <InfoCard
              title="Contribute"
              text="Contribute to your community by updating store prices, helping maintain up-to-date crowdsourced data"
              color="#664700"
              backgroundColor="#FFCF4F"
              video={ContributionMockup}
              replay={ReplayYellow}
              replayButton={true}
            />
          </div>
        </Fade>
        <Fade bottom>
          <div className="rewards-card-container">
            <InfoCard
              title="Rewards"
              text="Receive rewards for your contributions with gift cards to your preferred grocery stores"
              color="#105623"
              backgroundColor="#58E07C"
              image={RewardsMockup}
              replay={ReplayGreen}
            />
          </div>
        </Fade>
      </div>
      <Fade left>
        <div className="p2">Meet the Team</div>
      </Fade>
      <div className="founder-info-container">
        <Fade right>
          <div>
            <FounderInfo
              name="Aaron Mills"
              img={Aaron}
              linkedinLogo={LinkedInLogo}
              linkedinLink="https://www.linkedin.com/in/aaronmills0/"
              xLogo={XLogo}
              xLink="https://www.twitter.com/a_eye_m"
            />
          </div>
        </Fade>
        <Fade left>
          <div>
            <FounderInfo
              name="Joel Mills"
              img={Joel}
              linkedinLogo={LinkedInLogo}
              linkedinLink="https://www.linkedin.com/in/joelmills-"
            />
          </div>
        </Fade>
      </div>
      <Fade right>
        <div className="header">
          <Logo />
        </div>
      </Fade>
      <Tada>
        <div className="pricing-text">
          <h1>free to use, always.</h1>
        </div>
      </Tada>
      {/* <div className="download-container">
        <FormSubmitted name={name} />
      </div> */}
      <Fade left>
        <div className="p3">Download coming soon 💚</div>
        <div className="footer-text">2023 © Guava. All rights reserved.</div>
      </Fade>
      <Fade right>
        <div className="social-media-container">
          <a href="https://www.tiktok.com/@guavaapp?lang=en">
            <img className="tiktok" src={Tiktok} alt="tiktok" />
          </a>
          <a href="">
            <img className="instagram" src={Instagram} alt="instagram" />
          </a>
          <a href="">
            <img className="facebook" src={Facebook} alt="facebook" />
          </a>
          <a href="https://www.linkedin.com/company/goguava/">
            <img className="linkedin" src={Linkedin} alt="linkedin" />
          </a>
          <a href="https://twitter.com/go_guava">
            <img className="x" src={X} alt="x" />
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default MobileComponent;
