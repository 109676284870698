import React from "react";
import GuavaLogo from "../../assets/images/guava-logo.png";
import "./logo.scss";

const Logo = () => {
  return (
    <div className="logo-container">
      <img src={GuavaLogo} alt="Guava" />
      <div className="logo-text">
        <p className="logo-text__guava">guava</p>
      </div>
    </div>
  );
};

export default Logo;
