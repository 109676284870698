import React, { useEffect, useState } from "react";
import "./infoCard.scss";

interface Props {
  title: string;
  text: string;
  color: string;
  backgroundColor: string;
  image?: string;
  video?: string;
  replay: string;
  replayButton?: boolean;
}

const InfoCard: React.FC<Props> = ({
  title,
  text,
  color,
  backgroundColor,
  image,
  video,
  replay,
  replayButton,
}) => {
  useEffect(() => {
    if (replayButton) {
      setIsReplay(true);
    }
  }, [replayButton]);
  const [isReplay, setIsReplay] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(false);

  useEffect(() => {
    if (video) {
      setIsVideo(true);
    }
  }, [video]);

  const handleReplay = () => {
    const videoElement = document.querySelector(
      ".video-class"
    ) as HTMLVideoElement;

    if (videoElement) {
      videoElement.currentTime = 0;
    }
  };

  return (
    <div className="info-card" style={{ backgroundColor: backgroundColor }}>
      <div className="info-card-title" style={{ backgroundColor: color }}>
        <h2 style={{ color: backgroundColor }}>{title}</h2>
      </div>
      <p className="text-class" style={{ color: color }}>
        {text}
      </p>
      <div className="info-card-img">
        {isVideo ? (
          <video
            className="video-class"
            src={video}
            autoPlay
            loop
            muted
            preload="auto"
            poster={image}
          />
        ) : (
          <img className="image-class" src={image} alt="image" />
        )}
      </div>
      {isReplay && (
        <button
          className="replay-container"
          style={{ backgroundColor: color }}
          onClick={() => handleReplay()}
        >
          <p style={{ color: backgroundColor }}>Replay</p>
          <img src={replay} alt="replay" />
        </button>
      )}
    </div>
  );
};

export default InfoCard;
