import React, { useEffect, useState } from "react";

import MockupSide from "../../assets/images/mockup-side.png";
import RewardsMockup from "../../assets/images/rewards-mockup.png";
import ListMockup from "../../assets/images/list-mockup.png";
import ContributionMockup from "../../assets/videos/contribution-mockup.mp4";
import VideoBackup from "../../assets/images/videoBackup.jpg";

import Aaron from "../../assets/images/aaron.png";
import Facebook from "../../assets/images/facebook.png";
import Instagram from "../../assets/images/instagram.png";
import Joel from "../../assets/images/joel.png";
import Linkedin from "../../assets/images/linkedin.png";
import Tiktok from "../../assets/images/tiktok.png";
import X from "../../assets/images/x.png";

import LinkedInLogo from "../../assets/images/linkedin-logo.png";
import XLogo from "../../assets/images/x-logo.png";

import ContactButton from "../contactComponents/contactButton";
import Form from "../formComponents/form";
import FormSubmitted from "../formComponents/formSubmitted";
import Verification from "../formComponents/verification";
import FounderInfo from "../founderComponents/founderInfo";
import Logo from "../headerComponents/logo";
import InfoCard from "../howToComponents/infoCard";

import ReplayBlue from "../../assets/images/replay-blue.png";
import ReplayGreen from "../../assets/images/replay-green.png";
import ReplayYellow from "../../assets/images/replay-yellow.png";

import "../../_variables.scss";
import "./parallax.scss";

import { useParallax } from "react-scroll-parallax";

const ParallaxComponent: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  window.addEventListener("scroll", () => {
    const position = window.scrollY;
    setScrollPosition(position);
  });

  const height = window.innerHeight;

  const [isCardOneFixed, setIsCardOneFixed] = useState<boolean>(false);
  const [isCardTwoFixed, setIsCardTwoFixed] = useState<boolean>(false);
  const [isCardThreeFixed, setIsCardThreeFixed] = useState<boolean>(false);
  const [forward, setForward] = useState<boolean>(true);

  const handlePrivacyPolicy = () => {
    window.open(
      "https://docs.google.com/document/d/1FjR5K5HR-Nri50vfmLB1NomcneIK75BuzmqKBGe08s4/edit?usp=sharing"
    );
  };

  useEffect(() => {
    if (
      scrollPosition > (1090 / 783) * height &&
      scrollPosition < (3130 / 783) * height
    ) {
      setIsCardOneFixed(true);
    }
    if (scrollPosition < (1090 / 783) * height) {
      setIsCardOneFixed(false);
    }
    if (
      scrollPosition > (2270 / 783) * height &&
      scrollPosition < (3130 / 783) * height
    ) {
      setIsCardTwoFixed(true);
    }
    if (scrollPosition < (2270 / 783) * height) {
      setIsCardTwoFixed(false);
    }
    if (scrollPosition > (3130 / 783) * height) {
      setIsCardThreeFixed(true);
    }
    if (scrollPosition < (3130 / 783) * height) {
      setIsCardThreeFixed(false);
    }
    if (
      scrollPosition > (212 / 783) * height &&
      scrollPosition < (637 / 783) * height
    ) {
      setForward(false);
    }
    if (
      scrollPosition < (212 / 783) * height ||
      scrollPosition > (637 / 783) * height
    ) {
      setForward(true);
    }
  }, [scrollPosition]);

  const headerParallax = useParallax<HTMLDivElement>({
    startScroll: (0 / 783) * height,
    endScroll: (450 / 783) * height,
    translateX: ["0vw", "-75vw"],
  });

  const formParallax = useParallax<HTMLDivElement>({
    startScroll: (0 / 783) * height,
    endScroll: (600 / 783) * height,
    translateX: ["0vw", "120vw"],
    opacity: [1, 0],
  });

  const imageParallax = useParallax<HTMLDivElement>({
    startScroll: (0 / 783) * height,
    endScroll: (750 / 783) * height,
    translateX: ["0vw", "240vw"],
    opacity: [1, 0],
  });

  const textParallax = useParallax<HTMLDivElement>({
    startScroll: (250 / 783) * height,
    endScroll: (500 / 783) * height,
    opacity: [2, 0],
  });

  const cardOneParallax = useParallax<HTMLDivElement>({
    startScroll: (1410 / 783) * height,
    endScroll: (3130 / 783) * height,
    translateY: ["0vw", "-8vw"],
    scale: [1, 0.8],
  });

  const cardTwoParallax = useParallax<HTMLDivElement>({
    startScroll: (2270 / 783) * height,
    endScroll: (3130 / 783) * height,
    translateY: ["0vw", "-4.15vw"],
    scale: [1, 0.9],
  });

  const cardThreeParallax = useParallax<HTMLDivElement>({
    startScroll: (3130 / 783) * height,
    endScroll: (4000 / 783) * height,
  });

  const founderTextParallax = useParallax<HTMLDivElement>({
    startScroll: (3400 / 783) * height,
    endScroll: (3600 / 783) * height,
    opacity: [0, 1],
  });

  const founderImageParallaxOne = useParallax<HTMLDivElement>({
    startScroll: (3500 / 783) * height,
    endScroll: (3700 / 783) * height,
    scale: [0.4, 1],
    opacity: [0, 1],
    easing: "easeIn",
  });
  const founderImageParallaxTwo = useParallax<HTMLDivElement>({
    startScroll: (3500 / 783) * height,
    endScroll: (3700 / 783) * height,
    scale: [0.4, 1],
    opacity: [0, 1],
    easing: "easeIn",
  });

  const bottomLogoParallax = useParallax<HTMLDivElement>({
    startScroll: (4500 / 783) * height,
    endScroll: (4600 / 783) * height,
    translateX: ["-75vw", "0vw"],
    translateY: ["5vh", "0vh"],
  });

  // const downloadParallax = useParallax<HTMLDivElement>({
  //   startScroll: (4400 / 783) * height,
  //   endScroll: (4500 / 783) * height,
  //   translateY: ["40vh", "-0vh"],
  // });

  const socialMediaParallax = useParallax<HTMLDivElement>({
    startScroll: (4500 / 783) * height,
    endScroll: (4600 / 783) * height,
    scale: [0.6, 1],
  });

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [name, setName] = useState<string>("");

  return (
    <div className="parallax-container">
      <div className="contact-button-container">
        <ContactButton />
      </div>
      <div className="header" ref={headerParallax.ref}>
        <Logo />
      </div>
      <div className="form-container" ref={formParallax.ref}>
        {isSubmitted ? (
          emailVerified ? (
            <p className="welcome-text">
              Welcome aboard, <span>{name}</span>!
            </p>
          ) : (
            <Verification
              setEmailVerified={setEmailVerified}
              email={email}
              username={username}
            />
          )
        ) : (
          <Form
            setIsSubmitted={setIsSubmitted}
            setEmail={setEmail}
            setUsername={setUsername}
            setName={setName}
          />
        )}
      </div>
      <div className="image-container" ref={imageParallax.ref}>
        <img src={MockupSide} alt="mockupSide" />
      </div>
      <div className="text-container" ref={textParallax.ref}>
        <h1>
          Live Grocery Insights. <br /> Smart Savings Start Here.
        </h1>
      </div>
      <div>
        <div
          className={`save-card-container${isCardOneFixed ? "-fixed" : ""}`}
          ref={cardOneParallax.ref}
        >
          <InfoCard
            title="Save"
            text="Our real-time price comparison enables you to make informed choices and maximize your savings on groceries"
            color="#154F51"
            backgroundColor="#7BDADD"
            image={ListMockup}
            replay={ReplayBlue}
          />
        </div>
        <div
          className={`contribute-card-container${
            isCardTwoFixed ? "-fixed" : ""
          }`}
          ref={cardTwoParallax.ref}
        >
          <InfoCard
            title="Contribute"
            text="Contribute to your community by updating store prices, helping maintain up-to-date crowdsourced data"
            color="#664700"
            backgroundColor="#FFCF4F"
            video={ContributionMockup}
            image={VideoBackup}
            replay={ReplayYellow}
            replayButton={true}
          />
        </div>
        <div
          className={`rewards-card-container${
            isCardThreeFixed ? "-fixed" : ""
          }`}
          ref={cardThreeParallax.ref}
        >
          <InfoCard
            title="Rewards"
            text="Receive rewards for your contributions with gift cards to your preferred grocery stores"
            color="#105623"
            backgroundColor="#58E07C"
            image={RewardsMockup}
            replay={ReplayGreen}
          />
        </div>
      </div>
      <div className="last-page">
        <div className="p2" ref={founderTextParallax.ref}>
          Meet the Team
        </div>
        <div className="founder-info-container">
          <div ref={founderImageParallaxOne.ref} className="founder">
            <FounderInfo
              name="Aaron Mills"
              img={Aaron}
              linkedinLogo={LinkedInLogo}
              linkedinLink="https://www.linkedin.com/in/aaronmills0/"
              xLogo={XLogo}
              xLink="https://www.twitter.com/a_eye_m"
            />
          </div>
          <div ref={founderImageParallaxTwo.ref} className="founder">
            <FounderInfo
              name="Joel Mills"
              img={Joel}
              linkedinLogo={LinkedInLogo}
              linkedinLink="https://www.linkedin.com/in/joelmills-"
            />
          </div>
        </div>
        <div className="pricing-text">
          <h1>free to use, always.</h1>
        </div>
        {/* <div className="download-container" ref={downloadParallax.ref}>
          <FormSubmitted name={name} />
        </div> */}
        <div className="p3">Download coming soon 💚</div>
        <div className="footer-text">2023 © Guava. All rights reserved.</div>
        <div
          className="footer-text-right"
          onClick={() => handlePrivacyPolicy()}
        >
          Privacy Policy
        </div>
        <div className="header" ref={bottomLogoParallax.ref}>
          <Logo />
        </div>
        <div className="social-media-container" ref={socialMediaParallax.ref}>
          <a href="https://www.tiktok.com/@guavaapp?lang=en">
            <img className="tiktok" src={Tiktok} alt="tiktok" />
          </a>
          <a href="">
            <img className="instagram" src={Instagram} alt="instagram" />
          </a>
          <a href="">
            <img className="facebook" src={Facebook} alt="facebook" />
          </a>
          <a href="https://www.linkedin.com/company/goguava/">
            <img className="linkedin" src={Linkedin} alt="linkedin" />
          </a>
          <a href="https://twitter.com/go_guava">
            <img className="x" src={X} alt="x" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ParallaxComponent;
